import { Component, Input, computed, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { ApplicationInfoService } from '../core/application-info.service';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-part-type-item',
    standalone: true,
    imports: [CommonModule, RouterLink],
    template: `
        <div class="category-item">
            <div class="picture">
                @if (isWebComponent) {
                <a [href]="modelHref()" title="Show products in part type {{ model.name }}">
                    <ng-template [ngTemplateOutlet]="partTypePicture"></ng-template>
                </a>
                } @else {
                <a [routerLink]="model.path" title="Show products in part type {{ model.name }}">
                    <ng-template [ngTemplateOutlet]="partTypePicture"></ng-template>
                </a>
                }
            </div>
        </div>
        <h2 class="title">
            @if (isWebComponent) {
            <a [href]="modelHref()" title="Show products in part type {{ model.name }}">{{ model.name }}</a>
            } @else {
            <a [routerLink]="model.path" title="Show products in part type {{ model.name }}">{{ model.name }}</a>
            }
        </h2>

        <ng-template #partTypePicture>
            <img
                src="{{ model.imageUrl }}"
                alt="Picture for part type {{ model.name }}"
                title="Show products in part type {{ model.name }}"
            />
        </ng-template>
    `,
    styles: `
        .title {
            position: relative;
        }
    `
})
export class PartTypeItemComponent {
    appInfo = inject(ApplicationInfoService);
    isWebComponent = environment.isWebComponent;

    modelHref = computed(() => this.appInfo.addUtm(this.appInfo.shopUrl() + this.model.path));

    @Input() model!: PartTypeItemModel;
}

export class PartTypeItemModel {
    id!: string;
    name!: string;
    imageUrl!: string;
    path!: string;
}
