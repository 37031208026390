import { CommonModule } from '@angular/common';
import { Component, Input, computed, inject, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { PictureService } from '../core/picture.service';
import { StoreService } from '../core/store.service';
import { UtilityService } from '../core/utility.service';
import { PartTypeItemComponent, PartTypeItemModel } from './part-type-item.component';

@Component({
    selector: 'app-part-type-grid',
    standalone: true,
    imports: [CommonModule, RouterLink, PartTypeItemComponent],
    template: `
        @if (partTypeItems()!.length > 0) {
        <div class="category-grid home-page-category-grid">
            <div class="item-grid">
                @for(partTypeItem of partTypeItems(); track partTypeItem.id) {
                <app-part-type-item [model]="partTypeItem" class="item-box"></app-part-type-item>
                }
            </div>
        </div>
        }
    `,
    styles: ``,
})
export class PartTypeGridComponent {
    utilityService = inject(UtilityService);
    storeService = inject(StoreService);
    pictureService = inject(PictureService);
    partTypeItems = computed(() => {
        let partTypeItems: PartTypeItemModel[];
        switch (this._mode()) {
            case 'partType':
                partTypeItems = this.storeService.partTypes().map((partType) => {
                    return {
                        id: partType.id.toString(),
                        name: partType.name,
                        imageUrl: this.pictureService.getImageUrl('partType', partType.imageFileName, 400, undefined, 'max'),
                        path: partType.path,
                    };
                });
                break;
            default:
                partTypeItems = [];
                break;
        }

        if (partTypeItems.length === 1) partTypeItems = [];
        return this._showAll() ? partTypeItems : this.utilityService.pickRandomItems(partTypeItems, 3);
    });

    _showAll = signal<boolean>(false);
    @Input() set showAll(value: boolean) {
        this._showAll.set(value);
    }
    _mode = signal<'partType' | undefined>('partType');
    @Input() set mode(value: 'partType' | undefined) {
        this._mode.set(value);
    }
    _key = signal<string | undefined>(undefined);
    @Input() set key(value: string | undefined) {
        this._key.set(value);
    }
}
